import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  FieldReadFunction,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import Auth from '@aws-amplify/auth';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { getLogger } from 'src/pino/log-util';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import omitDeep from 'omit-deep-lodash';
import { store } from 'src/store';
import { showSnackbar } from 'src/slices/snackbar';
import { RetryLink } from '@apollo/client/link/retry';

const httpLink = new HttpLink({ uri: process.env.GRAPHQL_URL });

// Pino logger
const logger = getLogger('app');

// Exception to skip sentry logging
const skipSentryExceptions: string[] = ['Authorization header is required'];

async function isUserLoggedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

const withToken = setContext(async () => {
  const isLoggedIn: boolean = await isUserLoggedIn();
  if (isLoggedIn) {
    const session = await Auth.currentSession();

    const token = session.getAccessToken().getJwtToken();

    // For development only
    if (
      ['development', 'preview'].includes(
        process.env.NEXT_PUBLIC_VERCEL_ENV || '',
      )
    ) {
      console.log('Token: ', token);
    }

    return { token };
  } else {
    return {};
  }
});

// Build the authMiddleware
const authMiddleware = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  if (graphQLErrors) {
    const def = getMainDefinition(operation.query);
    const isMutation =
      def && (def as OperationDefinitionNode).operation === 'mutation';
    const isQuery =
      def && (def as OperationDefinitionNode).operation === 'query';

    //GraphQL operations ignored by the snackbar
    const ignore = [
      'CreateActivityLogs',
      'CreateGuestActivityLogs',
      'CreateActivityLog',
      'UpdateActivityLog',
      'RemoveActivityLog',
      'ViewMessage',
    ];

    // Guest paths that don't require authentication
    const guestPaths = [
      '/authentication/authorize',
      '/authentication/login',
      '/authentication/password-recovery',
      '/authentication/password-reset',
      '/authentication/register',
      '/authentication/verify-code',
      '/join',
      '/continue-register',
    ];

    if (isMutation && !ignore.includes(operation.operationName)) {
      store.dispatch(
        showSnackbar({
          message: `Error saving data`,
          type: 'error',
        }),
      );
    }

    if (isQuery && graphQLErrors?.length === 1) {
      const { code } = graphQLErrors[0].extensions as { code: string };
      if (code === '404') {
        window.location.href = '/404';
      }
    }

    graphQLErrors.forEach(({ message, locations, path }) => {
      logger.error(
        `[GraphQL error]: Operation: ${operation.operationName} Message: ${message}, Location: ${locations}, Path: ${path}`,
      );

      logger.debug({ message, locations, path, operation });

      // Skipping sentry logging for these exceptions
      if (skipSentryExceptions.includes(message)) {
        return;
      }

      Sentry.withScope(function (scope) {
        scope.setLevel('error');

        // The exception has the event level set by the scope (error).
        Sentry.captureException(
          new Error(
            `[GraphQL error]: Operation: ${operation.operationName} Message: ${message}`,
          ),
        );
      });
    });
  }
  if (networkError) {
    logger.error(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
  }
});

const cleanMutationFieldsLink = new ApolloLink((operation, forward) => {
  const keysToOmit = [
    '__typename',
    'claimCurrentUser',
    'claimUsers',
    'createdBy',
    'updatedBy',
    'deletedBy',
    'submittedBy',
    'awardedBy',
    'uploadedBy',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'submittedAt',
    'awardedAt',
    'uploadedAt',
    'lastSeenAt',
    'approvedBy',
    'approvedAt',
  ]; // more keys like timestamps could be included here

  const def = getMainDefinition(operation.query);
  if (def && (def as OperationDefinitionNode).operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

// Apollo client pull fields from cache
const queryFieldsPagination = (
  fields: string[],
): {
  [fieldName: string]: FieldPolicy | FieldReadFunction;
} => {
  const filedObject = {} as unknown as FieldPolicy<any>;
  for (const field of fields) {
    if (field === 'claimsList') {
      filedObject[field] = {
        keyArgs: false,
        merge(existing, incoming, { args: { pagination } }) {
          // Slicing is necessary because the existing data is
          // immutable, and frozen in development.
          const merged =
            existing && pagination?.offset > 0 ? existing.slice(0) : [];
          for (let i = 0; i < incoming.length; ++i) {
            merged[pagination?.offset + i] = incoming[i];
          }
          return merged;
        },
      };
    } else {
      filedObject[field] = {
        keyArgs: false,
        merge(existing, incoming, { args: { pagination } }) {
          // Slicing is necessary because the existing data is
          // immutable, and frozen in development.
          const merged = existing ? existing.slice(0) : [];
          for (let i = 0; i < incoming.length; ++i) {
            merged[pagination?.offset + i] = incoming[i];
          }
          return merged;
        },
      };
    }
  }

  return filedObject;
};

const retryLink = new RetryLink({
  delay: {
    initial: 30000, // The number of milliseconds to wait before attempting the first retry.
    max: 30000, // The maximum number of milliseconds that the link should wait for any retry.
    jitter: false, // Whether delays between attempts should be randomized.
  },
  attempts: {
    max: 5, // The max number of times to try a single operation before giving up.
    retryIf: (error, _operation) => !!error,
  },
});

const link = ApolloLink.from([
  withToken,
  retryLink,
  cleanMutationFieldsLink,
  errorLink,
  authMiddleware.concat(httpLink),
]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    typePolicies: {
      Claim: {
        keyFields: ['claimNumber'],
      },
      Query: {
        fields: queryFieldsPagination([
          'activityLogs',
          'claimStatusHistory',
          'claimsList',
        ]),
      },
    },
  }),
  // { addTypename: false }
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});

export default client;
