import { createContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ActivityLog, PayloadEvent } from 'src/types/activity-log';
import { v4 as uuidv4 } from 'uuid';

import CREATE_ACTIVITY_LOG from 'src/graphql/activity-logs/mutations/create-activity-logs.graphql';
import CREATE_GUEST_ACTIVITY_LOG from 'src/graphql/activity-logs/mutations/create-guest-activity-logs.graphql';
import { useRouter } from 'next/router';
import Auth from '@aws-amplify/auth';
import { appConfig } from 'src/config';

async function isUserLoggedIn() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

export const ActivityLogCtx = createContext<any>(null);

export function ActivityLogWrapper({ children }) {
  const router = useRouter();

  let initialLogs: ActivityLog[] = [];
  const localLogs: string | null = localStorage.getItem('activity-logs');
  if (localLogs !== null) {
    initialLogs = JSON.parse(localLogs) || [];
  }

  const [activityLog, setActivityLog] = useState<ActivityLog[]>(initialLogs);

  const [createActivityLog] = useMutation(CREATE_ACTIVITY_LOG);
  const [createGuestActivityLog] = useMutation(CREATE_GUEST_ACTIVITY_LOG);

  const createLog = async (log: ActivityLog, unload = false) => {
    log.logId = uuidv4();

    const payloadEvent: PayloadEvent = {
      requestUrl: window.location.href,
      requestPath: window.location.pathname,
      queryParams: window.location.href,
    };

    const activity: ActivityLog = {
      logId: log.logId,
      subject: log.subject,
      subjectRef: log.subjectRef || '',
      gqlOperation: log.gqlOperation || '',
      claimNumber: log.claimNumber || '',
      appType: 'FE',
      appVersion:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'development',
      eventType: log.eventType,
      event: log.event,
      payload: payloadEvent,
      createdAt: new Date(),
    };

    if (unload) {
      // Required sync operation to post activity log instantly
      const allLogs: ActivityLog[] = activityLog;
      allLogs.push(activity);

      setActivityLog(allLogs);
      await handleActivityLogMutation(true).catch(console.error);
    } else {
      // Spread operator does not work synchronously but it is required to update state
      setActivityLog([...activityLog, activity]);
    }

    return true;
  };

  const handleActivityLogMutation = async (unload = false) => {
    if (activityLog.length >= parseInt(appConfig.activityLogsBuffer.toString()) || unload) {
      if (activityLog.length > 0) {
        const postingLogs: ActivityLog[] = activityLog;

        const isLoggedIn = await isUserLoggedIn();
        if (isLoggedIn) {
          createActivityLog({
            variables: { createActivityLogInput: { logs: activityLog } },
            onCompleted: data => {
              setActivityLog(
                activityLog.filter(item => {
                  return postingLogs.indexOf(item.logId) === 1;
                }),
              );

              localStorage.setItem(
                'activity-logs',
                JSON.stringify(
                  activityLog.filter(item => {
                    return postingLogs.indexOf(item.logId) === 1;
                  }),
                ),
              );
            },

            onError: error => {
              console.error(error);
            },
          });
        } else {
          createGuestActivityLog({
            variables: { createActivityLogInput: { logs: activityLog } },
            onCompleted: data => {
              setActivityLog(
                activityLog.filter(item => {
                  return postingLogs.indexOf(item.logId) === 1;
                }),
              );

              localStorage.setItem(
                'activity-logs',
                JSON.stringify(
                  activityLog.filter(item => {
                    return postingLogs.indexOf(item.logId) === 1;
                  }),
                ),
              );
            },

            onError: error => {
              console.error(error);
            },
          });
        }
      }
    }
  };

  const handleUnload = () => {
    handleActivityLogMutation(true).catch(console.error);
  };

  // Handling Activity Log mutation
  useEffect(() => {
    //Adding new activity log to localstorage
    localStorage.setItem('activity-logs', JSON.stringify(activityLog));

    handleActivityLogMutation(false).catch(console.error);
  }, [activityLog]);

  // Loading Client IP address
  useEffect(() => {
    // Attaching event listener for unload
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.addEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <ActivityLogCtx.Provider value={{ activityLog, setActivityLog, createLog }}>
      {children}
    </ActivityLogCtx.Provider>
  );
}
