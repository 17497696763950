import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';

import CURRENT_USER_QUERY from '../graphql/users/queries/current-user.graphql';
import { AppAbility, buildAbilityForSubject } from 'src/lib/ability';
import { EventTrackerCtx } from './event-tracker';

export const UserContext = createContext({
  currentUser: {},
  setCurrentUser: (user: any) => {},
  loadingUser: true,
  setLoadingUser: (loading: boolean) => {},
  ability: {} as AppAbility,
});

export function UserContextWrapper({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [loadingUser, setLoadingUser] = useState(false);
  const { identify } = useContext(EventTrackerCtx);

  // Setting default guest ability
  const [ability, setAbility] = useState<AppAbility>(
    buildAbilityForSubject([{ subject: 'User', action: 'guest' }]),
  );

  // Loading user in user query
  const { data, loading, error } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.currentUser) {
      setCurrentUser(data?.currentUser);
      identify(data?.currentUser);
      setAbility(
        buildAbilityForSubject(data?.currentUser?.userAbilities?.abilities),
      );
    }
    setLoadingUser(loading);
  }, [data, loading]);

  useEffect(() => {
    setAbility(buildAbilityForSubject(currentUser?.userAbilities?.abilities));
  }, [currentUser]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        loadingUser,
        setLoadingUser,
        ability,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function userCurrentUserContext() {
  return useContext(UserContext);
}

export default UserContext;
